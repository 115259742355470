/* eslint-disable indent */
import React, { useCallback } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'styles/components/BlogTop.scss';
// import { Main } from './BlogTop.styled';

const BlogTop = () => {
  const data = useStaticQuery(graphql`
    query BlogTopQuery {
      allContentfulBlogPost(
        sort: { fields: [publishDate], order: DESC } #      limit: 10
        filter: {
          category: {
            elemMatch: { name: { nin: ["Product Updates", "Product Videos"] } }
          }
        }
      ) {
        edges {
          node {
            id
            title
            slug
            createdAt
            publishDate(formatString: "MMM DD, YYYY")
            archiveThumbnail {
              fluid(
                maxWidth: 600
                maxHeight: 456
                quality: 70
                resizingBehavior: THUMB
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            excerpt {
              excerpt
            }
            knowledgeBox
            category {
              name
            }
          }
        }
      }
      allContentfulCustomerStory(
        sort: { fields: publishDate, order: DESC }
        limit: 1
      ) {
        nodes {
          id
          excerpt
          title
          tag
          slug
          createdAt
          featuredImage {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const getBlogPosts = useCallback(
    (type) =>
      data.allContentfulBlogPost.edges.filter((p) => {
        if (type === 'kb') {
          return p.node.knowledgeBox === true;
        }
        return !p.node.knowledgeBox;
      }),
    [data.allContentfulBlogPost.node],
  );

  return (
    <section className="blog-top-section">
      <div className="blog-filters-main">
        <div className="container">
          <div className="filter-block">
            <button
              className="active"
              type="button"
              // onClick={categoryToggle('')}
            >
              All Blogs
            </button>
            {/* {allCategory.map((p, i) => (
                <button
                  key={p.node.id}
                  className={`category-${i} ${
                    category === p.node.name ? 'active' : ''
                  }`}
                  type="button"
                  onClick={categoryToggle(p.node.name)}>
                  {p.node.name}
                </button>
              ))} */}
            <Link to="/product-updates/">Product Updates</Link>
            <Link to="/product-videos/">Product Videos</Link>
            <Link to="/customer-stories/">Customer Stories</Link>
            {/* <Link to="/blog/write-for-us/">Write for us</Link> */}
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          {/* <div className="blog-hero">
              <h1 className="center">Blog</h1>
              <p>
                Here we bring you useful tips &amp; tricks for both designers
                &amp; developers, tell you more about ruttl, and share some
                illuminating secrets about the universe. Okay, maybe not the
                last one.
              </p>
            </div> */}
          <div className="blog-hero">
            <div className="flex flex-wrap">
              <div className="latest-blog-wrapper">
                {data.allContentfulCustomerStory.nodes.length > 0 && (
                  <div className="top-post">
                    <div style={{ width: '100%' }}>
                      <Img
                        alt={data.allContentfulCustomerStory.nodes[0].title}
                        fluid={
                          data.allContentfulCustomerStory.nodes[0].featuredImage
                            .fluid
                        }
                      />
                    </div>
                    <div className="latest-post-wrapper">
                      <span className="blog-tag">
                        {data.allContentfulCustomerStory.nodes[0].tag}
                      </span>
                      <h2>{data.allContentfulCustomerStory.nodes[0].title}</h2>
                      <p>{data.allContentfulCustomerStory.nodes[0].excerpt}</p>
                      <Link
                        to={`/customer-stories/${data.allContentfulCustomerStory.nodes[0].slug}/`}
                        className="button">
                        Read More
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 512 512">
                          <path
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="48"
                            d="M268 112l144 144-144 144M392 256H100"></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <div className="knowledge-box-wrapper">
                <h4>Knowledge Box</h4>
                <div className="knowledge-overflow">
                  {getBlogPosts('kb').map((p, index) =>
                    index > 9 ? (
                      ''
                    ) : (
                      <Link
                        key={p.node.id}
                        className="single-knowledge-box"
                        to={`/blog/${p.node.slug}/`}>
                        <div className="knowledge-txt">
                          <p>{p.node.title}</p>
                          <div className="knowledge-catogory-date">
                            {/* eslint-disable indent */}
                            {Array.isArray(p.node.category) &&
                              p.node.category.length > 0 && (
                                <span className="knowledge-catogory-date--category">
                                  {p.node.category
                                    .map((c) => c.name)
                                    .join(', ')}
                                </span>
                              )}
                            {/* eslint-enable indent */}
                            <span>{p.node.publishDate}</span>
                          </div>
                        </div>
                        <Img
                          alt={p.node.title}
                          className="knowledge-img"
                          fluid={p.node.archiveThumbnail.fluid}
                        />
                      </Link>
                    ),
                  )}
                </div>
                <Link to="/knowledge-box/" className="button">
                  Show All
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default BlogTop;
